import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/DiscoverLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Overview`}</h1>
    <p>{`CoreMedia client-side personalization provides the base functionality for integrating third-party web personalization, optimization,
and testing providers with CoreMedia.`}</p>
    <p>{`You can use Studio to define and use, for example, experiences.`}</p>
    <p>{` `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "854px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7b5217c345f302abf21b969796b751c1/89c55/experience_definition_form.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABOklEQVQoz51TW26DMBD0/U9F8hFVCtD0BgRBMBGBmKfBwETjQNSPqiVdabSyvVrvzNiirmvUVQUpJa7pFbfbDcYYDMPwJ1gnZYI8z6GUQpIkEDvHwX63w8fhAPd4xKfn4eT7m+F7HnzXxdfphL3jQKRpCplIFHmBvu+huw7dG9Ba2zyOI85BANG1LUibG9M04T8xz7PNyeUCUdcVgiCw2lEHrXuYwbwu2ALWMuI4huDIZVmiqio7aakUlCotfRZSeOYfwbPFQNswiiA4WZZlthkP2MhqqfVmUENOGp7PEFxwurZtX3qs+C1WqisLxoWUzfKmeNO0Ubd5mqxMfH/3+93q3zQNIlImvXZxmjdtNYL1NJGNmftheGq4OvRuUCo2ZeZbLori2ZAbqxlbvtv3b2cxGutBpzXCMMQD9xSew6afimcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7b5217c345f302abf21b969796b751c1/e93cc/experience_definition_form.webp 300w", "/static/7b5217c345f302abf21b969796b751c1/b0544/experience_definition_form.webp 600w", "/static/7b5217c345f302abf21b969796b751c1/565b8/experience_definition_form.webp 854w"],
              "sizes": "(max-width: 854px) 100vw, 854px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7b5217c345f302abf21b969796b751c1/eed55/experience_definition_form.png 300w", "/static/7b5217c345f302abf21b969796b751c1/7491f/experience_definition_form.png 600w", "/static/7b5217c345f302abf21b969796b751c1/89c55/experience_definition_form.png 854w"],
              "sizes": "(max-width: 854px) 100vw, 854px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/7b5217c345f302abf21b969796b751c1/89c55/experience_definition_form.png",
              "alt": "experience definition form",
              "title": "experience definition form",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`Defining Experience in Studio`}</em></p>
    <h2>{`Use Cases`}</h2>
    <p>{`Based on the capability of the provider the following use cases are supported:`}</p>
    <ul>
      <li parentName="ul">{`Optimization and Testing: Run experiences with split traffic allocation (A/B/n tests) and targeting`}</li>
      <li parentName="ul">{`Personalization: Run experiences with machine learning and targeting`}</li>
      <li parentName="ul">{`Segmentation: Use targeting capabilities of the provider to serve custom content experiences to different user segments`}</li>
    </ul>
    <p>{`For all these use cases, content editors in CoreMedia Studio can easily create and manage content and connect them to the
provider for optimized and personalized delivery.`}</p>
    <h2>{`Prerequisites`}</h2>
    <p>{`In order to use Client-Side Personalization you need to install the p13n-core and the p13n-adapter-generic. See the links
below.`}</p>
    <h2>{`Resources`}</h2>
    <ul>
      <li parentName="ul">{`Learn how to use the `}<a parentName="li" {...{
          "href": "https://github.com/coremedia-contributions/p13n-core"
        }}>{`Personalization API`}</a>{` and create your own adapters.`}</li>
      <li parentName="ul">{`As an example, study the `}<a parentName="li" {...{
          "href": "https://github.com/coremedia-contributions/p13n-adapter-monetate"
        }}>{`Monetate Adapter`}</a>{` to integrate Monetate
for personalization, segmentation and optimization of content for specific user groups.`}</li>
      <li parentName="ul">{`As an example, study the `}<a parentName="li" {...{
          "href": "https://github.com/coremedia-contributions/p13n-adapter-generic"
        }}>{`Generic Personalization Adapter`}</a>{`
which comes with default implementations for Dynamic Yield and Evergage.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      